import { computed } from "vue";
import { RouteComponent, RouteRecordName, RouteRecordRaw, RouteRecordRedirectOption, useRouter } from "vue-router";
import { MessageOrId, Shortcut } from "@ui/components";
import { AuthPolicy } from "../auth/policies";
import { useUserStore } from "../auth/user";
import {
    isSettingTruthBoolean,
    LoadingStatus,
    ToggleConfig,
    useFeatureTogglesStore,
    useWawiConfigsStore,
} from "../configs/wawiConfigs";
import { WaWiCountry } from "../country/country";
import { LocationType, WaWiLocation } from "../location/location";
import { WaWiPreview } from "../preview/preview";
import { GuidedTourStep } from "./GuidedTour";
import { useSkeletonStore } from "./store";

type PageRoute<TCategory extends string = string> = {
    /**
     * Will be used as default path of this route
     */
    name: string;
    /**
     * URL path for this route
     * If you use parameters in the path, it will not appear in the generic root page.
     * Path default will be `/${name}`.
     * @default `/${name}`
     */
    path?: string;
    /**
     * Is used to assign a route to a subcategory.
     * Make sure to create a matching category in the `categories` section of the RouteConfig.
     * Has an effect on how it is sorted into the navigation menu.
     *
     */
    categoryId?: TCategory;
    /**
     * Title for the navigation menu item, is required for the route to be listed in the TopBarDropdown.
     */
    titleMsg: MessageOrId;
    /**
     * Description for the navigation menu item.
     * Default will be `${name}`
     * @default `${name}`
     */
    description?: string;
    requiredPolicy?: AuthPolicy | AuthPolicy[];
    requiredToggles?: ToggleConfig[];
    allowedCountries?: string[];
    /**
     * Route will be available or unavailable depending on the Setting with this section and this name
     * being parsable to a positive boolean. "1" and case-insensitive "true" strings will be evaluated to True.
     * Anything else will be evaluated to false (unavailable route)
     * If you provide a list of true values only these will be evaluated as true.
     */
    activationSetting?: { section: string; name: string; trueValues?: string[] };
    guidedTourSteps?: GuidedTourStep[];
    locationType?: LocationType;
    /**
     * Keyboard shortcut to be used in the TopBarDropDown
     */
    shortcut?: Shortcut;
    /**
     * If this path doesn't depend on the location and shouldn't be transformed.
     */
    noLocation?: boolean;
    component: RouteComponent | (() => Promise<RouteComponent>);

    redirect?: never;

    /**
     * Don't show the page in the overview, even if it has a title and had no dynamic parameter in the URL.
     */
    hidden?: boolean;
    /**
     * Show the page in the overview as disabled
     */
    disabledMsg?: MessageOrId;
    /**
     * Show a tooltip with the info message
     */
    infoMsg?: MessageOrId;
};

type RedirectRoute = {
    path: string;
    redirect: RouteRecordRedirectOption;

    name?: never;
    categoryId?: never;
    titleMsg?: never;
    requiredPolicy?: never;
    requiredToggles?: never;
    activationSetting?: never;
    guidedTourSteps?: never;
    locationType?: never;
    shortcut?: never;
    noLocation?: never;
    component?: never;
    hidden?: never;
};

type WaWiRoute<TCategory extends string = string> = PageRoute<TCategory> | RedirectRoute;

export type RouteCategory = {
    id: string;
    labelMsg: MessageOrId;
    shortcut?: Shortcut;
    hidden?: boolean;
    categories?: RouteCategory[];
};

export type RouteConfig<TCategories extends RouteCategory[] = RouteCategory[]> = {
    categories?: TCategories;
    pages: WaWiRoute<TCategories[number]["id"]>[];
};

const isRedirectRoute = (r: WaWiRoute): r is RedirectRoute => !!(r as RedirectRoute).redirect;

const isCategoryInvalid = (categories: RouteCategory[] | undefined, id: string): boolean =>
    !categories || categories.every((c) => c.id !== id && isCategoryInvalid(c.categories, id));

export const routeConfigToRouteRecords = (routeConfig: RouteConfig): RouteRecordRaw[] =>
    routeConfig.pages.map((page) => {
        if (isRedirectRoute(page)) {
            return page;
        }
        if (page.categoryId && isCategoryInvalid(routeConfig.categories, page.categoryId)) {
            throw new Error(
                `No category with id "${page.categoryId}" found in the categories section of the route config. Erroneous route with name "${page.name}".`,
            );
        }
        return {
            name: page.name,
            path: page.path || `/${page.name}`,
            component: page.component,
        };
    });

export type ResolvedRoute = PageRoute & {
    category?: RouteCategory;
};

export const configForRouteName = (
    routeName: RouteRecordName | null | undefined,
    routeConfig: RouteConfig | undefined,
): ResolvedRoute | undefined => {
    const config = routeConfig?.pages.find((route): route is PageRoute => "name" in route && route.name === routeName);

    if (config) {
        return {
            ...config,
            category: routeConfig?.categories?.find((c) => c.id === config?.categoryId),
        };
    }
};

export type RouteOption = {
    value: string;
    labelMsg: MessageOrId;
    shortLabel: string;
    categoryId?: string;
    shortcut?: Shortcut;
    dataComponentMetadata?: { id: string; policies: string };
    href?: string;
    disabledMsg?: MessageOrId;
    infoMsg?: MessageOrId;
};

export type PageRouteWithTitleMsg = PageRoute & { titleMsg: MessageOrId };

export const useModuleRouting = () => {
    const router = useRouter();
    const userStore = useUserStore();
    const wawiConfigsStore = useWawiConfigsStore();
    const featureTogglesStore = useFeatureTogglesStore();
    const skeletonStore = useSkeletonStore();

    const availableRoutes = computed<{
        categories: RouteCategory[];
        options: RouteOption[];
        byName: Record<string, ResolvedRoute>;
    }>(() => {
        const { routeConfig } = skeletonStore;

        // We need to know in advance how many elements we put into the availableRoutes due to a limitation
        // with the core UI component that renders the route options. This can probably be simplified once
        // we reimplement the list component.
        const allDependenciesLoaded = routeConfig?.pages.every((r) => {
            const { requiredPolicy, activationSetting, requiredToggles } = r;
            if (requiredPolicy && !userStore.isLoggedIn) {
                return false;
            }
            if (activationSetting && wawiConfigsStore.configsStatus !== LoadingStatus.READY) {
                return false;
            }
            if (requiredToggles && featureTogglesStore.featureTogglesStatus !== LoadingStatus.READY) {
                return false;
            }
            return true;
        });

        const availableCategories = routeConfig?.categories ?? [];
        const hiddenCategories = availableCategories.filter((c) => c.hidden);

        // Hint: The type is actually PageRouteWithTitleMsg[], but TS will fail if too many messages are being used
        //       with exceeded the maximum amount of complexity. That's why we cast it to any[]. So be careful when working with the result.
        const availableRoutes = allDependenciesLoaded
            ? ((routeConfig?.pages.filter((route) => {
                  if ("redirect" in route) return false;
                  const {
                      requiredPolicy,
                      activationSetting,
                      requiredToggles,
                      locationType,
                      hidden,
                      path,
                      allowedCountries,
                      categoryId,
                  } = route;

                  if (hiddenCategories.some((c) => c.id === categoryId)) {
                      return false;
                  }

                  if (requiredPolicy && !userStore.hasPolicy(requiredPolicy)) {
                      return false;
                  }

                  if (allowedCountries && !allowedCountries.some((c) => c === WaWiCountry.get())) {
                      return false;
                  }

                  if (
                      activationSetting &&
                      // obtaining a parsed boolean setting is currently not possible
                      // after story WAM-MZ40KZ is finished, this code should be adapted to obtain the setting as boolean directly
                      !isSettingTruthBoolean(
                          wawiConfigsStore.GetSettingValue(activationSetting.section, activationSetting.name),
                          activationSetting.trueValues,
                      )
                  ) {
                      return false;
                  }

                  if (
                      requiredToggles &&
                      requiredToggles.some((t) => featureTogglesStore.isFeatureEnabled(t.name) === !!t.hideIfActive)
                  ) {
                      return false;
                  }
                  if (locationType === LocationType.WH && !WaWiLocation.isWarehouse()) {
                      return false;
                  }
                  if (locationType === LocationType.HQ && !WaWiLocation.isHeadquarter()) {
                      return false;
                  }
                  if (path?.includes(":")) {
                      return false;
                  }

                  return !hidden;
              }) as any[]) ?? [])
            : [];

        const params = router.currentRoute.value.params;
        const hasParams = params && !params.notFoundPath && Object.keys(params).length > 0;

        return {
            categories: availableCategories,
            options: availableRoutes.map<RouteOption>((route) => ({
                value: route.name,
                labelMsg: route.titleMsg,
                shortLabel: route.description || route.name,
                categoryId: route.categoryId,
                shortcut: route.shortcut,
                dataComponentMetadata: WaWiPreview.dataComponentMetadata(route.requiredPolicy),
                href: hasParams ? router.resolve({ name: route.name, params }).href : "",
                disabledMsg: route.disabledMsg,
                infoMsg: route.infoMsg,
            })),
            byName: Object.fromEntries(availableRoutes.map((route): [string, ResolvedRoute] => [route.name, route])),
        };
    });

    const navigateTo = async (name: string, metaKey: boolean): Promise<void> => {
        const newRoute = availableRoutes.value.byName[name];
        const currentRoute = router.currentRoute.value;
        if (currentRoute.name !== newRoute?.name) {
            if (metaKey) {
                const { href } = router.resolve({ name: newRoute?.name, params: currentRoute.params });
                window.open(href, "_blank");
            } else {
                await router.push({ name: newRoute?.name, params: currentRoute.params });
            }
        }
    };

    return { availableRoutes, navigateTo };
};

export type NavigateToModuleOpts = Partial<{
    openMode: "push" | "replace" | "newTab";
    queryParams: Record<string, string>;
}>;
/**
 *
 * Navigates from one module to another.
 *
 * @param moduleName e.g. xwh, MIL, cpp. Converted to lowercase automatically.
 * @param location e.g. WH091, HQ001. Converted to lowercase automatically.
 * @param maskName e.g. MIL001, CPP001. Converted to uppercase automatically.
 * @param opts.openMode: "push" (same tab + add to history stack), "replace" (same tab + replace topmost element history stack), or "newTab"
 * @param opts.queryParams: additional query parameters to be added to the URL
 */
export const navigateToModule = (
    moduleName: string,
    location: string,
    maskName: string,
    opts: NavigateToModuleOpts = {},
) => {
    const { openMode = "push", queryParams = {} } = opts;
    const query = new URLSearchParams(queryParams).toString();
    const to = `${window.location.origin}/module/${moduleName.toLowerCase()}/${location.toLowerCase()}/${maskName.toUpperCase()}${query ? `?${query}` : ""}`;
    const OPEN_MODE_TO_NAVIGATION_ACTION = {
        newTab: () => window.open(to, "_blank"),
        push: () => window.open(to, "_self"),
        replace: () => window.location.replace(to),
    } satisfies Record<Required<NavigateToModuleOpts>["openMode"], () => void>;
    OPEN_MODE_TO_NAVIGATION_ACTION[openMode]();
};

export const RouteUtils = {
    navigateToModule,
};
