<script lang="ts" setup>
import { CSSProperties, onMounted, onUnmounted, watch } from "vue";
import logoDirty from "../../../assets/images/logo_dirty.svg";
import logoFavicon from "../../../assets/images/logo_favicon.svg";
import { ComponentUtils } from "../../utils";
import WuxTopBar, { BackAction } from "../WuxTopBar/WuxTopBar.vue";

const props = defineProps<{
    /**
     * Will be displayed in the top bar as title.
     */
    title: string;
    /**
     * Will be displayed the loading indicator.
     */
    isLoading?: boolean;
    /**
     * Handles the action triggered when the user navigates back. It also defines a custom icon displayed on the far left side of the header.
     */
    backAction?: BackAction;
    /**
     * The 'Load new version' button will be displayed under certain conditions.
     */
    newVersionAvailable?: boolean;
    /**
     * Update UI to reflect changes when the application is in a modified state, which also alters the appearance of the favicon.
     */
    isDirty?: boolean;
    /**
     * Used when custom styles need to be applied.
     */
    style?: CSSProperties;
}>();

// later we will also use this link to update the logo depending on the dirty state of the UI
const link = document.createElement("link");

link.rel = "shortcut icon";
watch(
    () => props.isDirty,
    (nextDirty) => {
        link.href = nextDirty ? logoDirty : logoFavicon;
    },
    { immediate: true },
);
document.head.appendChild(link);

onMounted(() => {
    if (ComponentUtils.isMobile()) {
        document.body.classList.add("is-mobile");
    }
});

onUnmounted(() => {
    document.body.classList.remove("is-mobile");
});
</script>

<template>
    <WuxTopBar
        :title="props.title"
        :isLoading="props.isLoading"
        :backAction="props.backAction"
        :newVersionAvailable="props.newVersionAvailable"
        :style="props.style"
    >
        <template #top-bar-right>
            <!-- @slot Use this slot to inject custom content into the right side of the top bar -->
            <slot name="top-bar-right" />
        </template>
    </WuxTopBar>

    <main>
        <!-- @slot This slot is used for injecting the main content into the WuxLayout component -->
        <slot name="main" />
    </main>
</template>

<style lang="scss" scoped>
@use "../../../assets/styles/mixins.scss";

main {
    margin: var(--wux-page-margins-x) var(--wux-page-margins-y);
    @media print {
        margin: 0;
    }

    @include mixins.isMobile {
        --wux-page-margins-y: 2rem;
    }

    &:has(.wux-dead-end, .wux-banner) {
        margin-top: 0;
    }

    &:has(.wux-bottom-bar--page) {
        margin-bottom: calc(var(--wux-bottom-bar-height) + var(--wux-page-margins-x));
    }
}
</style>
