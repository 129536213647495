<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { Icons } from "../../icons";
import { ClickUtil } from "../../utils/click";
import { Shortcut, Shortcuts, ShortcutUtil } from "../../utils/shortcuts";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import { PopoverTargetProps, usePopover } from "../WuxPopover/WuxPopover.core";
import WuxPopover from "../WuxPopover/WuxPopover.vue";

const props = defineProps<{
    open?: boolean;
    /**
     * Needs to be defined initially, can not be changed afterwards.
     */
    shortcut?: Shortcut;
}>();

const { vWuxPopoverTarget, popoverProps } = usePopover();

const emit = defineEmits<{ (e: "update:open", isOpen: boolean): void }>();

const onToggle = () => emit("update:open", !props.open);

const popProps: PopoverTargetProps = {
    openMechanism: "manual",
};

const ProfileMenuRef = ref<HTMLDivElement | null>(null);

onMounted(() => {
    props.shortcut &&
        ShortcutUtil.registerGlobal(props.shortcut, (e) => {
            e.preventDefault();
            onToggle();
        });
    // close the profile menu if click outside the profile menu options
    if (ProfileMenuRef.value) {
        ClickUtil.registerOutsideClick([ProfileMenuRef.value], () => {
            // ... but only if it is actually open
            if (props.open) {
                onToggle();
            }
        });
    }
});

const onKeydown = (event: KeyboardEvent) => {
    const onSelect = (e: KeyboardEvent) => {
        e.preventDefault();
        onToggle();
    };

    const onEscape = (e: KeyboardEvent) => {
        if (!props.open) return;
        e.preventDefault();
        onToggle();
    };

    return ShortcutUtil.getKeyDownHandler(
        [Shortcuts.Tab, onToggleByKeyboard],
        [Shortcuts.Tab.shift, onToggleByKeyboard],
        [Shortcuts.Escape, onEscape],
        [Shortcuts.Space, onSelect],
        [Shortcuts.Enter, onSelect],
    )(event);
};

// we run this callback only if the profile menu is currently open
const onToggleByKeyboard = () => props.open && onToggle();

const handleClick = () => {
    emit("update:open", false);
};
</script>

<template>
    <div v-wux-popover-target="popProps" ref="ProfileMenuRef" class="wux-profile-menu">
        <div @click="onToggle" class="wux-profile-menu__button" @keydown="onKeydown" tabindex="0">
            <WuxIcon :src="Icons.profile" :size="20" />
        </div>
        <WuxPopover v-bind="popoverProps" class="wux-profile-menu__popover" :isOpen="props.open" alignment="right">
            <div @click="handleClick" v-if="props.open">
                <slot />
            </div>
        </WuxPopover>
    </div>
</template>

<style lang="scss">
.wux-profile-menu {
    &__button {
        font-weight: 600;
        font-size: 14px;
        padding: 0.7em 0.6em 0.3em 0.6em;
        cursor: pointer;
        border-radius: 0;

        &.disabled {
            opacity: 0.8;
        }

        &:hover {
            background-color: var(--wawi-color-grayscale-white-overlay);
        }
        &:focus-visible {
            outline: none;
            border-color: transparent;
            box-shadow:
                inset 2px 2px 0px white,
                inset -2px -2px 0px white;
        }
    }
    &__popover {
        background-color: var(--wawi-color-grayscale-white);
        border: 1px solid var(--wawi-color-primary-500);
        min-width: 13rem; // to match the scu style
        // to avoid a strange behavior while scrolling
        top: var(--wux-top-bar-height) !important;
        position: fixed;
    }
}
</style>
