<script lang="ts" setup>
import { useMsgFormatter } from "../../i18n";
import { Shortcut } from "../../utils/shortcuts";

const props = defineProps<{
    shortcut: Shortcut;
    isMac?: boolean;
    /**
     * smaller variant of the key shortcut for inline usage
     */
    inline?: boolean;
}>();

const { m } = useMsgFormatter();

// see here: https://apple.stackexchange.com/questions/55727/where-can-i-find-the-unicode-symbols-for-mac-functional-keys-command-shift-e
const codeMappings: Record<string, string | undefined> = {
    ArrowDown: "&darr;",
    ArrowUp: "&uarr;",
    ArrowLeft: "&larr;",
    ArrowRight: "&rarr;",
    Backquote: "^",
    Backspace: "⌫",
    Escape: "esc",
};
const renderCode = (code: string): string => {
    if (code.startsWith("Key")) return code.substring(3);
    if (code.startsWith("Digit")) return code.substring(5);
    return codeMappings[code] || code;
};
</script>

<template>
    <div class="wux-shortcut-keys" :class="{ inline: props.inline }">
        <template v-if="shortcut.__.ctrl">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <kbd v-if="props.isMac">&#8963;</kbd>
            <kbd v-else>{{ m("ui.components.shortcuts.windows.ctrl") }}</kbd>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span>+</span>
        </template>
        <template v-if="shortcut.__.meta">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <kbd v-if="props.isMac">&#8984;</kbd>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <kbd v-else>⊞</kbd>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span>+</span>
        </template>
        <template v-if="shortcut.__.alt">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <kbd v-if="props.isMac">&#8997;</kbd>
            <kbd v-else>{{ m("ui.components.shortcuts.windows.alt") }}</kbd>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span>+</span>
        </template>
        <template v-if="shortcut.__.shift">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <kbd>&#8679;</kbd>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span>+</span>
        </template>
        <kbd v-html="renderCode(shortcut.__.code)" />
    </div>
</template>

<style lang="scss">
.wux-shortcut-keys {
    display: flex;
    gap: 0.5em;
    align-items: center;

    kbd {
        background-color: var(--wawi-color-neutral-800);
        color: var(--wawi-color-grayscale-white);
        padding: 0.25em 0.5em;
        font-size: 1.25rem;
        border-radius: 10%;
        white-space: nowrap;
    }

    &.inline {
        display: flex-inline;
        gap: 0.125em;

        kbd {
            padding: 2px 0.25em 1px 0.25em;
            line-height: 1rem;
            font-size: inherit;
        }
    }
}
</style>
